export type PresetOption = {
    name: string;
    group: string;
    filePath: string;
    isVisible: boolean;
}

export const presetOptions: PresetOption[] = [
    {
        name: 'Custom / Choose a Template',
        group: '',
        filePath: '/app/exports//app/exports/export_templates/custom/custom_-_choose_a_template.json',
        isVisible: true
    },
    {
        name: 'All',
        group: '',
        filePath: '/app/exports/export_templates/all/all.json',
        isVisible: true
    },
    {
        name: 'Template String',
        group: '',
        filePath: 'template_string',
        isVisible: true
    },
    {
        name: 'Rakuten - GSP',
        group: 'Rakuten Advertising',
        filePath: '/app/exports/export_templates/rakuten/rakuten_rad_gsp.json',
        isVisible: false // Access determined by the following templates: 'rakuten_export_templates', 'rakuten_test_export_templates'
    },
    {
        name: 'Rakuten - Publisher Full',
        group: 'Rakuten Advertising',
        filePath: '/app/exports/export_templates/rakuten/rakuten_publisher_full.json',
        isVisible: false // Access determined by the following templates: 'rakuten_export_templates', 'rakuten_test_export_templates'
    },
    {
        name: 'Rakuten - Publisher Delta',
        group: 'Rakuten Advertising',
        filePath: '/app/exports/export_templates/rakuten/rakuten_publisher_delta.json',
        isVisible: false // Access determined by the following templates: 'rakuten_export_templates', 'rakuten_test_export_templates'
    },
    {
        name: 'Rakuten - Publisher Full - Test',
        group: 'Rakuten Advertising',
        filePath: '/app/exports/export_templates/rakuten/rakuten_publisher_full_test.json',
        isVisible: false // Access determined by the following templates: 'rakuten_test_export_templates'
    },
    {
        name: 'Rakuten - Publisher Delta - Test',
        group: 'Rakuten Advertising',
        filePath: '/app/exports/export_templates/rakuten/rakuten_publisher_delta_test.json',
        isVisible: false // Access determined by the following templates: 'rakuten_test_export_templates'
    },
    {
        name: 'Google Shopping',
        group: 'Comparison Shopping Engine',
        filePath: '/app/exports/export_templates/google/google_shopping.json',
        isVisible: true
    },
    {
        name: 'Microsoft Ads',
        group: 'Comparison Shopping Engine',
        filePath: '/app/exports/export_templates/bing/bing_shopping.json',
        isVisible: true
    },
    {
        name: 'Google Manufacturer Center',
        group: 'Comparison Shopping Engine',
        filePath: '/app/exports/export_templates/google/google_manufacturer_center.json',
        isVisible: true
    },
    {
        name: 'Google Shopping Content API - Lite',
        group: 'Comparison Shopping Engine',
        filePath: '/app/exports/export_templates/google/google_shopping_content_api_lite.json',
        isVisible: true
    },
    {
        name: 'Google Shopping Content API - Full',
        group: 'Comparison Shopping Engine',
        filePath: '/app/exports/export_templates/google/google_shopping_content_api.json',
        isVisible: true
    },
    {
        name: 'Regional Availablity Feed Google Content API',
        group: 'Comparison Shopping Engine',
        filePath: '/app/exports/export_templates/google/regional_availability_feed_content_api.json',
        isVisible: true
    },
    {
        name: 'Promotions Feed Google Content API',
        group: 'Comparison Shopping Engine',
        filePath: '/app/exports/export_templates/google/promotions_feed_content_api.json',
        isVisible: true
    },
    {
        name: 'Region Feed Google Content API',
        group: 'Comparison Shopping Engine',
        filePath: '/app/exports/export_templates/google/region_feed_content_api.json',
        isVisible: true
    },
    {
        name: 'Shopzilla',
        group: 'Comparison Shopping Engine',
        filePath: '/app/exports/export_templates/shopzilla/shopzilla.json',
        isVisible: true
    },
    {
        name: 'Pricesearcher',
        group: 'Comparison Shopping Engine',
        filePath: '/app/exports/export_templates/pricesearcher/pricesearcher.json',
        isVisible: true
    },
    {
        name: 'Nextag',
        group: 'Comparison Shopping Engine',
        filePath: '/app/exports/export_templates/nextag/nextag.json',
        isVisible: true
    },
    {
        name: 'Google Dynamic Text Ad Campaigns',
        group: 'Dynamic Text Ads',
        filePath: '/app/exports/export_templates/google/google_dynamic_text_ad_campaigns.json',
        isVisible: true
    },
    {
        name: 'Facebook API DPA',
        group: 'Remarketing',
        filePath: '/app/exports/export_templates/facebook/facebook_api_dpa.json',
        isVisible: true
    },
    {
        name: 'Facebook FTP Dynamic Product Ads',
        group: 'Remarketing',
        filePath: '/app/exports/export_templates/facebook/facebook_dynamic_product_ads.json',
        isVisible: true
    },
    {
        name: 'Facebook Automotive Catalog',
        group: 'Remarketing',
        filePath: '/app/exports/export_templates/facebook/facebook_automotive.json',
        isVisible: true
    },
    {
        name: 'Criteo',
        group: 'Remarketing',
        filePath: '/app/exports/export_templates/criteoremaketing/crit_template.json',
        isVisible: true
    },
    {
        name: 'Snap',
        group: 'Remarketing',
        filePath: '/app/exports/export_templates/snap/snap.json',
        isVisible: true
    },
    {
        name: 'Pinterest',
        group: 'Social',
        filePath: '/app/exports/export_templates/pinterest/pinterest.json',
        isVisible: true
    },
    {
        name: 'BazaarVoice',
        group: 'Reviews',
        filePath: '/app/exports/export_templates/bazaar/bazaar_voice.json',
        isVisible: true
    },
    {
        name: 'Social Native fka Olapic',
        group: 'Social',
        filePath: '/app/exports/export_templates/olapic/olapic.json',
        isVisible: true
    },
    {
        name: 'TikTok',
        group: 'Social',
        filePath: '/app/exports/export_templates/tiktok/tiktok.json',
        isVisible: true
    },
    {
        name: 'LinkConnector',
        group: 'Affiliate',
        filePath: '/app/exports/export_templates/linkconnector/linkconnector.json',
        isVisible: true
    },
    {
        name: 'ShareASale',
        group: 'Affiliate',
        filePath: '/app/exports/export_templates/shareasale/shareasale.json',
        isVisible: true
    },
    {
        name: 'Searchspring',
        group: 'On Site Search',
        filePath: '/app/exports/export_templates/searchspring/searchspring.json',
        isVisible: true
    },
    {
        name: 'Monetate',
        group: 'Personalization',
        filePath: '/app/exports/export_templates/monetate/monetate.json',
        isVisible: true
    },
    {
        name: 'Movable Ink',
        group: 'Personalization',
        filePath: '/app/exports/export_templates/movable_ink/movable_ink.json',
        isVisible: true
    },
    {
        name: 'Jobs2Careers',
        group: 'Job Board',
        filePath: '/app/exports/export_templates/jobs2careers/jobs2careers.json',
        isVisible: true
    },
    {
        name: 'Jopwell',
        group: 'Job Board',
        filePath: '/app/exports/export_templates/jopwell/jopwell.json',
        isVisible: true
    },
    {
        name: 'Workopolis',
        group: 'Job Board',
        filePath: '/app/exports/export_templates/workopolis/workopolis.json',
        isVisible: true
    },
    {
        name: 'ZipRecruiter',
        group: 'Job Board',
        filePath: '/app/exports/export_templates/ziprecruiter/ziprecruiter.json',
        isVisible: true
    },
    {
        name: 'ZipRecruiter (Benefits)',
        group: 'Job Board',
        filePath: '/app/exports/export_templates/ziprecruiter/ziprecruiter_benefits.json',
        isVisible: true
    },
    {
        name: 'ZipRecruiter (DSP)',
        group: 'Job Board',
        filePath: '/app/exports/export_templates/ziprecruiter/ziprecruiter_dsp.json',
        isVisible: true
    },
    {
        name: 'ZipRecruiter (UKG)',
        group: 'Job Board',
        filePath: '/app/exports/export_templates/ziprecruiter/ziprecruiter_ukg.json',
        isVisible: true
    },
    {
        name: 'ZipAlerts',
        group: 'Job Board',
        filePath: '/app/exports/export_templates/zipalerts/zipalerts.json',
        isVisible: true
    },
    {
        name: 'Clickcast',
        group: 'Job Board',
        filePath: '/app/exports/export_templates/clickcast/clickcast.json',
        isVisible: true
    },
    {
        name: 'Attentive',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/attentive/attentive.json',
        isVisible: true
    },
    {
        name: 'eBay Commerce Network',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/ebay/ebay_commerce_network.json',
        isVisible: true
    },
    {
        name: 'Houzz Product Feed',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/houzz/houzz_product_feed.json',
        isVisible: true
    },
    {
        name: 'Houzz Inventory Update/Delete feed',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/houzz/houzz_inventory_update-delete_feed.json',
        isVisible: true
    },
    {
        name: 'Kijiji API',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/kijiji/kijiji_api.json',
        isVisible: true
    },
    {
        name: 'Kijiji Automotive - Cargigi',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/kijiji/kijiji_automotive_cargigi.json',
        isVisible: true
    },
    {
        name: 'Kijiji E-commerce XML',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/kijiji/kijiji_ecom_xml.json',
        isVisible: true
    },
    {
        name: 'Kijiji Jobs XML',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/kijiji/kijiji_jobs_xml.json',
        isVisible: true
    },
    {
        name: 'Kijiji Jobs Webhook',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/kijiji/kijiji_jobs_webhook.json',
        isVisible: true
    },
    {
        name: 'ShopStyle SKU Feed (NDJSON)',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/shopstyle/shopstyle_sku_feed_ndjson.json',
        isVisible: false // $scope.accountHasFeature('shopstyle_export_template', 'enabled')
    },
    {
        name: 'Vivino',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/vivino/vivino.json',
        isVisible: true
    },
    {
        name: 'Amazon - Inventory Loader',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/amazon/inventory_loader.json',
        isVisible: true
    },
    {
        name: 'Amazon - Price and Quantity',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/amazon/price_and_quantity.json',
        isVisible: true
    },
    {
        name: 'Amazon Seller Central - Product Feed (Inventory Template file format)',
        filePath: '/app/exports/export_templates/amazon/amazon_seller_central_product_feed_inventory_template_file_format.json',
        group: 'Marketplace',
        isVisible: true
    },
    {
        name: 'Amazon Seller Central - Product Feed (SP-API JSON format)',
        filePath: '/app/exports/export_templates/amazon/amazon_seller_central_product_feed_spapi_json_format.json',
        group: 'Marketplace',
        isVisible: true
    },
    {
        name: 'eBay API - Price and Inventory',
        filePath: '/app/exports/export_templates/ebay/ebay_api_price_and_inventory.json',
        group: 'Marketplace',
        isVisible: true
    },
    {
        name: 'eBay API - Product Feed',
        filePath: '/app/exports/export_templates/ebay/ebay_api_product_feed.json',
        group: 'Marketplace',
        isVisible: true
    },
    {
        name: 'Etsy - Price and Inventory',
        filePath: '/app/exports/export_templates/etsy/etsy_price_and_inventory.json',
        group: 'Marketplace',
        isVisible: true
    },
    {
        name: 'Facebook/Instagram/Meta Shops - Inventory Feed',
        filePath: '/app/exports/export_templates/facebook/facebook_instagram_meta_shops_inventory_feed.json',
        group: 'Marketplace',
        isVisible: true
    },
    {
        name: 'Mirakl - Export Offers',
        filePath: '/app/exports/export_templates/mirakl/mirakl_export_offers.json',
        group: 'Marketplace',
        isVisible: true
    },
    {
        name: 'Newegg - Inventory Feed',
        filePath: '/app/exports/export_templates/newegg/newegg_inventory_feed.json',
        group: 'Marketplace',
        isVisible: true
    },
    {
        name: 'Newegg - Price Feed',
        filePath: '/app/exports/export_templates/newegg/newegg_price_feed.json',
        group: 'Marketplace',
        isVisible: true
    },
    {
        name: 'Newegg - Product Feed',
        filePath: '/app/exports/export_templates/newegg/newegg_product_feed.json',
        group: 'Marketplace',
        isVisible: true
    },
    {
        name: 'Shein - Product Feed',
        filePath: '/app/exports/export_templates/shein/shein_product_feed.json',
        group: 'Marketplace',
        isVisible: true
    },
    {
        name: 'Shein - Price and Inventory Feed',
        filePath: '/app/exports/export_templates/shein/shein_price_and_inventory_feed.json',
        group: 'Marketplace',
        isVisible: true
    },
    {
        name: 'Target - Product Feed (without Disable)',
        filePath: '/app/exports/export_templates/target/target_product_feed_without_disable.json',
        group: 'Marketplace',
        isVisible: true
    },
    {
        name: 'Target - Update Price',
        filePath: '/app/exports/export_templates/target/target_update_price.json',
        group: 'Marketplace',
        isVisible: true
    },
    {
        name: 'Target - Update Quantity and Price',
        filePath: '/app/exports/export_templates/target/target_update_quantity_and_price.json',
        group: 'Marketplace',
        isVisible: true
    },
    {
        name: 'TikTok - Inventory and Price Feed',
        filePath: '/app/exports/export_templates/tiktok/tiktok_inventory_and_price_feed.json',
        group: 'Social',
        isVisible: true
    },
    {
        name: 'TikTok - Product Feed',
        filePath: '/app/exports/export_templates/tiktok/tiktok_product_feed.json',
        group: 'Social',
        isVisible: true
    },
    {
        name: 'Walmart - Inventory Feed',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/walmart/inventory_feed.json',
        isVisible: true
    },
    {
        name: 'Walmart - Prices Feed',
        filePath: '/app/exports/export_templates/walmart/walmart_prices_feed.json',
        group: 'Marketplace',
        isVisible: true
    },
    {
        name: 'Walmart - Promotion Feed',
        filePath: '/app/exports/export_templates/walmart/walmart_promotion_feed.json',
        group: 'Marketplace',
        isVisible: true
    },
    {
        name: 'Walmart - v4 WFS Item Conversion Feed',
        filePath: '/app/exports/export_templates/walmart/walmart_v4_wfs_item_conversion_feed.json',
        group: 'Marketplace',
        isVisible: true
    },
    {
        name: 'Walmart v4 - Setup By Match Feed',
        filePath: '/app/exports/export_templates/walmart/walmart_v4_setup_by_match_feed.json',
        group: 'Marketplace',
        isVisible: true
    },
    {
        name: 'Walmart - v5 Product Feed',
        filePath: '/app/exports/export_templates/walmart/walmart_v5_product_feed.json',
        group: 'Marketplace',
        isVisible: true
    }
];
